import * as React from "react"
import { Link } from "gatsby"

import FadeIn from "react-fade-in/lib/FadeIn"
import PageLayout from "../components/page-layout"
import ExternalLink from "../components/external-link"
import KoyaImg from "../images/koya.png"

import "./index.css"
import '../styles/animations.css'

const IndexPage = () => {
  return (
    <PageLayout title="grac">
      <body id="home-page" className="page">
        <div className="center-container">
          <FadeIn>
            <h1 className="page-title" id="home-page-title">
              <span style={{ color: "var(--pink)" }}>H</span>
              <span style={{ color: "var(--yellow)" }}>E</span>
              <span style={{ color: "var(--green)" }}>L</span>
              <span style={{ color: "var(--lightblue)" }}>L</span>
              <span style={{ color: "var(--darkblue)" }}>O</span>
              <span style={{ color: "var(--purple)" }}>!</span>
              {/* <span style={{ color: "var(--pink)" }}>)</span> */}
            </h1>

            <div style={{ textAlign: "center" }}>
              <Link to="/">
                <img
                  src={KoyaImg}
                  id='koya-img'
                  style={{
                    width: "50%",
                    margin: "0px 25% 0px 25%",
                    maxWidth: "280px",
                    transform: "translate(0px, 0px)",
                  }}
                />
              </Link>
            </div>

           <div style={{ textAlign: "center"}}>
            <br/>
            <p>under construction :-)</p>
           </div>

            {/* <ul className="home-navbar">
              <li className="home-link">
                <Link to="/about/" activeStyle={{ color: "black" }}>
                  about
                </Link>
              </li>
              <li className="home-link">
                <ExternalLink href="https://www.linkedin.com/in/gracj">
                  work
                </ExternalLink>
              </li>
              <li className="home-link">
                <Link to="/projects/" activeStyle={{ color: "black" }}>
                  projects
                </Link>
              </li>
            </ul> */}
           
          </FadeIn>
        </div>
        <div style={{ height: "50px" }} />
      </body>
    </PageLayout>
  )
}

export default IndexPage
